import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/i18n.js'

Vue.use(ElementUI)
Vue.use(ElementUI, { size: 'small', zIndex: 2000 })

// // 引入语言包
// import en from './locale/en.json'
// import zh from './locale/zh.json'

// Vue.use(i18n)

// // 配置语言选项
// const messages = {
//   en,
//   zh,
// }
// // 设置语言实例
// const i18n = new VueI18n({
//   locale: 'en', // 默认语言
//   fallbackLocale: 'en', // 备用语言
//   messages, // 语言包
// });

Vue.config.productionTip = false

new Vue({
  i18n,
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
