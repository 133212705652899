// src/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 引入语言文件
import en from './en.js'
import zh from './zh.js'

Vue.use(VueI18n)

// 创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'zh', // 设置默认语言
  messages: {
    en,
    zh
  }
})

export default i18n
