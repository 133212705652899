// 设置 localStorage 数据并添加有效期
export function setLocalStorageWithExpiry(key, value, expiryInMinutes) {
    const now = new Date();

    // 检查是否已有数据
    const existingData = getLocalStorageWithExpiry(key) || {};

    // 合并新的值
    const updatedData = { ...existingData, ...value };

    const item = {
        value: JSON.stringify(updatedData), // 将合并后的值转换为 JSON 字符串
        expiry: now.getTime() + expiryInMinutes * 60 * 1000, // 设置过期时间
    };
    localStorage.setItem(key, JSON.stringify(item));
}

// 获取 localStorage 数据并判断是否过期
export function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    try {
        // 尝试将字符串解析为对象
        return JSON.parse(item.value);
    } catch (e) {
        // 如果解析失败，返回原始字符串
        return item.value;
    }
}

// 为已有的 localStorage 对象添加新属性，并保持过期时间
export function addPropertiesToLocalStorage(key, newProperties) {
    // 获取已有对象并检查是否过期
    const existingData = getLocalStorageWithExpiry(key);

    if (existingData) {
        // 获取原过期时间
        const itemStr = localStorage.getItem(key);
        const item = JSON.parse(itemStr);

        // 将新属性合并到现有对象中
        const updatedData = { ...existingData, ...newProperties };

        // 使用原有的过期时间重新存储
        const newItem = {
            value: JSON.stringify(updatedData),
            expiry: item.expiry, // 保留原有过期时间
        };
        //console.log('获取kayasdaAAAAs111',newItem)
        localStorage.setItem(key, JSON.stringify(newItem));
    } else {
        console.log("The item does not exist or has expired.");
    }
}
// 示例用法
// 初次设置 'myData'，10分钟有效期
//setLocalStorageWithExpiry('myData', { name: 'test', age: 25 }, 10);

// 在不更改过期时间的前提下添加新属性
//addPropertiesToLocalStorage('myData', { gender: 'male', phone: '13514251425' });

// 检查数据
//const data = getLocalStorageWithExpiry('myData');
//console.log(data); // 输出包含新属性的对象,没有返回null
