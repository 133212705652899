<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/>-->
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'app',
    components: {
      Header,
      Footer, // 在 App.vue 中全局引入 Footer
    },
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // width: 100%;  //设置宽度为100%
}

html, body {
    margin: 0; // 清除默认的间距
    padding: 0; // 清除默认的间距
}

body {
     background-color: black; // 在全局样式中定义网站用的背景颜色为黑色
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
