import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import {getLocalStorageWithExpiry} from '../utils/setLocalStorage'
Vue.use(VueRouter)

/* 定义路由 */
export const routes = [
 {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index')
  },
  {
    path: '/quote', // 行情
    name: 'quote',
    component: () => import('@/views/quote/index')
  },
  {
    path: '/trade', // 加密货币1-现货交易
    name: 'trade',
    component: () => import('@/views/trade/index')
  },
  {
    path: '/tradeleverage', // 加密货币1 --杠杆交易
    name: 'tradeleverage',
    component: () => import('@/views/trade/tradeleverage')
  },
  {
    path: '/tradeoption', // 加密货币1 -期权交易
    name: 'tradeoption',
    component: () => import('@/views/trade/tradeoption')
  },
  {
    path: '/stock', // 股票2-现货交易
    name: 'stock',
    component: () => import('@/views/stock/index')
  },
  {
    path: '/stockpry', // 股票2 --杠杆交易
    name: 'stockpry',
    component: () => import('@/views/stock/stockpry')
  },
  {
    path: '/stockoption', // 股票2 -期权交易
    name: 'stockoption',
    component: () => import('@/views/stock/stockoption')
  },
  {
    path: '/forex', // 外汇3-现货交易
    name: 'forex',
    component: () => import('@/views/forex/index')
  },
  {
    path: '/forexpry', // 外汇3 --杠杆交易
    name: 'forexpry',
    component: () => import('@/views/forex/forexpry')
  },
  {
    path: '/forexoption', //外汇3 -期权交易
    name: 'forexoption',
    component: () => import('@/views/forex/forexoption')
  },
  {
    path: '/futures', // 期货4-现货交易
    name: 'futures',
    component: () => import('@/views/futures/index')
  },
  {
    path: '/futurespry', // 期货4 --杠杆交易
    name: 'futurespry',
    component: () => import('@/views/futures/futurespry')
  },
  {
    path: '/futuresoption', // 期货4 -期权交易
    name: 'futuresoption',
    component: () => import('@/views/futures/futuresoption')
  },
  {
    path: '/lockup', //锁仓挖矿首页
    name: 'lockup',  //锁仓挖矿首页
    component: () => import('@/views/lockup/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/lockdetails', //锁仓挖矿详情页
    name: 'lockdetails',//锁仓挖矿详情页
    component: () => import('@/views/lockup/lockdetails'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/quantitative', //量化交易首页
    name: 'quantitative',  //量化交易首页
    component: () => import('@/views/quantitative/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/quantdetails', //量化交易详情页
    name: 'quantdetails',//量化交易详情页
    component: () => import('@/views/quantitative/quantdetails'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/afpcfp', //理财首页
    name: 'afpcfp',//理财首页
    component: () => import('@/views/afpcfp/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/afpcfpdetail', //理财详情页
    name: 'afpcfpdetail',//理财详情页
    component: () => import('@/views/afpcfp/afpcfpdetail'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/myproperty', //我的资产首页
    name: 'myproperty',//我的资产首页
    component: () => import('@/views/myproperty/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/recharge', //资产首页---立即充值页
    name: 'recharge',
    component: () => import('@/views/recharge/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/withdraw', //资产首页---立即提现页
    name: 'withdraw',
    component: () => import('@/views/withdraw/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/wallet', //资产首页---我的钱包页
    name: 'wallet',
    component: () => import('@/views/wallet/index')
  },
  {
    path: '/rechargebank',//资产首页---充值银行自选区
    name: 'rechargebank',
    component: () => import('@/views/recharge/rechargebank')
  },
  {
    path: '/rechargerecord', //资产首页---充值银行自选区充值记录
    name: 'rechargerecord',
    component: () => import('@/views/recharge/rechargerecord')
  },
  {
    path: '/myhold', //资产首页---我的持有
    name: 'myhold',
    component: () => import('@/views/myhold/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/usercenter', //个人中心
    name: 'usercenter',
    component: () => import('@/views/usercenter/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/centerrecord', //个人中心财务记录
    name: 'centerrecord',
    component: () => import('@/views/centerrecord/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/myteam', //个人中心---我的团队
    name: 'myteam',
    component: () => import('@/views/myteam/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/certification', // 实名认证
    name: 'certification',
    component: () => import('@/views/certification/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/advancedcertif', // 高级认证
    name: 'advancedcertif',
    component: () => import('@/views/advancedcertif/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/bindcard', // 绑定银行卡
    name: 'bindcard',
    component: () => import('@/views/bindcard/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/loginpass', // 登录密码
    name: 'loginpass',
    component: () => import('@/views/loginpass/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/transactionpass', // 交易密码
    name: 'transactionpass',
    component: () => import('@/views/transactionpass/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/invitefriends', // 邀请好友
    name: 'invitefriends',
    component: () => import('@/views/invitefriends/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/platform', // 平台介绍
    name: 'platform',
    component: () => import('@/views/platform/index')
  },
  {
    path: '/notice', // 平台公告介绍
    name: 'notice',
    component: () => import('@/views/notice/index')
  },
  {
    path: '/helpcenter', // 帮助中心
    name: 'helpcenter',
    component: () => import('@/views/helpcenter/index')
  },
  {
    path: '/helpdata', // 帮助中心--详情页
    name: 'helpdata',
    component: () => import('@/views/helpcenter/details')
  },
  {
    path: '/loan', // 贷款申请
    name: 'loan',
    component: () => import('@/views/loan/index'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/record', // 贷款申请记录
    name: 'record',
    component: () => import('@/views/loan/record'),
    meta: { requiresAuth: true } // 需要 token 的页面
  },
  {
    path: '/login', // 登录
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/register', // 注册
    name: 'register',
    component: () => import('@/views/register/index')
  },
  {
    path: '/forgetpass', // 忘记密码
    name: 'forgetpass',
    component: () => import('@/views/forgetpass/index')
  },
  {
    path: '/bindcardRecord', // 忘记密码
    name: 'bindcardRecord',
    component: () => import('@/views/bindcard/bindcardRecord')
  },
  {
	  path: '/certificationRecord', // 忘记密码
	  name: 'certificationRecord',
	  component: () => import('@/views/certificationRecord/index')
  }
  

]


/* 防止连续点击多次路由报错 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch((err) => err)
  /* return routerPush.call(this, location).catch((err) => {
    console.error('Router push error:', err);
  }) */
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL, // process.env.BASE_URL通常是由 VueCLI自动生成的,它的默认值为 '/',表示应用运行在网站的根路径。
  routes,
  scrollBehavior(to, from, savedPosition) {
      // 如果是从一个历史记录跳转（例如点击浏览器的返回按钮），恢复滚动位置
      if (savedPosition) {
        return savedPosition;
      }
      // 如果是新的路由跳转，滚动到顶部
      return { x: 0, y: 0 };
    }
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 检查路由是否需要 token
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 获取 token
    const token = getLocalStorageWithExpiry('myData')?.token;
    if (token) {
      // 如果 token 存在，允许访问
      next();
    } else {
      Message({
        message: '请登录!',
        type: 'error',
        duration: 1500 //设置 1500 毫秒自动关闭
      });
      next({
        path: '/login',
        query: { redirect: to.fullPath } //保存跳转前的页面路径，登录后可重定向回去
      });
    }
  } else {
    // 不需要 token 的页面直接允许访问
    next();
  }
});
export default router
