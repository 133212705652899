// src/locales/zh.js
export default {
  index: {
    a1: '立即购买',
    a2: '加密货币',
    a3: '股票',
    a4: '外汇',
    a5: '期货',
    a6: '名称',
    a7: '最新成交价',
    a8: '24小时涨跌幅',
    a9: '24小时最高',
    a10: '24小时最低',
    a11: '24小时交易额',
    a12: '操作',
    a13: '交易',
  },
  advancedcertif: {
    a1:'高级认证',
    a2:'为了保障用户安全，我们需要认证你的身份信息注（真实并且有效证件）并提交到FINRA，我们不会泄露您的任何信息，保障用户的隐私权益',
    a3:'姓名',
    a4:'请输入姓名',
    a5:'证件类型',
    a6:'请输入证件类型',
    a7:'证件正面',
    a8:'证件反面',
    a9:'手持证件照',
    a10:'提交',
    a11:'身份证',
    a12:'身份证',
    a13:'护照',
    a14:'驾驶证',
    a15:'请填写姓名!',
    a16:'请上传正面照!',
    a17:'请上传反面照!',
    a18:'请上传手持照!',
    a19:'上传头像图片只能是 JPG 格式!',
    a20:'上传头像图片大小不能超过 2MB!',
  },
  afpcfp:{
    a1:'文章详情',
    a2:'理财交易介绍',
    a3:'什么是理财交易？',
    a4:'理财交易是一款帮助拥有闲置数字资产的用户，通过专业算法实现投资回报的理财产品。依托严格的风控体系，充分保障用户交易的安全性。',
    a5:'*请注意：历史收益不能代表未来收益。我们不承诺在一定期限内以货币、实物、股权等方式还本付息，或提供任何其他形式的固定收益回报。',
    a6:'产品优势：',
    a7:'稳健型',
    a8:'依托严格的风控体系和数据分析，保障用户资产安全，享受稳健收益。',
    a9:'收益从何而来？',
    a10:'理财交易通过市场数据分析和投资策略，在符合市场趋势的条件下进行交易，从而为用户带来一定的收益。',
    a11:'什么时候发放收益？',
    a12:'申购成功后，用户可在 T+1 天起开始计息，并按周期收到收益。',
    a13:'收益如何计算？',
    a14:'例如：以第一档投资金额计算，500 USDT * 0.15% = 0.75 USDT，这是每天的预期收益。',
    a15:'赎回什么时候能到账？',
    a16:'投资周期内无法手动赎回，需等待周期结束后自动赎回，本金和收益将实时到账。',
  },
  afpcfpIndex:{
    a1:'理财交易',
    a2:'理财交易?',
    a3:'智能投资账户(USD)',
    a4:'累计收益(USD)',
    a5:'项目周期：',
    a6:'天',
    a7:'最低起投数(USD)',
    a8:'每日回报率',
    a9:'手续费',
    a10:'立即投资',
    a11:'我的订单',
    a12:'项目',
    a13:'开始时间',
    a14:'结束时间',
    a15:'累计收益',
    a16:'委托金额',
    a17:'手续费',
    a18:'状态',
    a19:'锁仓中',
    a20:'已完成',
    a21:'空数据',
    a22:'产品名称：',
    a23:'项目周期：',
    a24:'天',
    a25:'日返佣率：',
    a26:'存款金额（USD）',
    a27:'可用金额：',
    a28:'确 定',
    a29:'定单确认',
    a30:'15天锁仓',
    a31:'您的余额不足!',
    a32:'买入金额不能低于最小金额!',
    a33:'请输入存款金额!',
  },
  bindcard:{
    a1:'银行卡绑定',
    a2:'绑定银行卡后，您可以享受更方便的支付和提现服务，但也需要对账户安全负责，确保手机和银行卡的安全。',
    a3:'姓名',
    a4:'请输入姓名',
    a5:'银行卡号',
    a6:'请输入银行卡号',
    a7:'银行名称',
    a8:'请输入银行名称',
    a9:'联系电话(选填)',
    a10:'请输入联系电话',
    a11:'支行名称(选填)',
    a12:'请输入支行名称',
    a13:'支行代码(选填)',
    a14:'请输入支行代码',
    a15:'备注(选填)',
    a16:'请输入备注',
    a17:'绑定',
    a18:'身份证',
    a19:'护照',
    a20:'驾驶证',
    a21:'请填写姓名！',
    a22:'请填写银行卡号！',
    a23:'请填写正确的银行卡号！',
    a24:'请填写银行名字！',
    a25:'请填写正确的手机号格式!',
	a26:'绑定记录',
  },
  centerrecord:{
    a1:'账户记录',
    a2:'全部',
    a3:'充值成功',
    a4:'提现',
    a5:'操作类型',
    a6:'金额',
    a7:'变动前',
    a8:'变动后',
    a9:'时间'
  },
  certification:{
    a1:'实名认证',
    a2:'高级认证',
    a3:'为了保障用户安全，我们需要认证你的身份信息注（真实并且有效证件）并提交到FINRA，我们不会泄露您的任何信息，保障用户的隐私权益',
    a4:'姓名',
    a5:'请输入姓名',
    a6:'证件类型',
    a7:'请输入名',
    a8:'证件正面',
    a9:'证件反面',
    a10:'提交',
    a11:'身份证',
    a12:'护照',
    a13:'驾驶证',
    a14:'请填写姓名！',
    a15:'请上传正面照！',
    a16:'请上传反面照！',
    a17:'上传头像图片只能是 JPG 格式！',
    a18:'上传头像图片大小不能超过 2MB!',
	a19:'认证记录'
  },
  forexoption:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'期权交易',
    a19:'交易方式',
    a20:'选择到期时间',
    a21:'买入数量',
    a22:'最低买入',
    a23:'请输入买入数量',
    a24:'可用余额',
    a25:'买 涨',
    a26:'买 跌',
    a27:'当前委托',
    a28:'历史委托',
    a29:'名称',
    a30:'方向',
    a31:'委托数',
    a32:'时间',
    a33:'开仓价',
    a34:'平仓价',
    a35:'预计收益',
    a36:'收益',
    a37:'手续费',
    a38:'开始时间',
    a39:'结束时间',
    a40:'看涨',
    a41:'看跌',
    a42:'数量',
    a43:'收益',
    a44:'价格',
    a45:'确 定',
    a46:'请您登录',
    a47:'买入数量不能低于最低买入数量!',
    a48:'您的余额不足!',
  },
  forexpry:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'杠杆交易',
    a19:'做多',
    a20:'做空',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'选择倍数',
    a25:'下单张数',
    a26:'1张等于',
    a27:'请输入下单张数',
    a28:'市值',
    a29:'保证金',
    a30:'可用',
    a31:'当前委托',
    a32:'持仓中',
    a33:'已完成',
    a34:'已撤单',
    a35:'已完成',
    a36:'名称',
    a37:'类型',
    a38:'手数',
    a39:'倍数',
    a40:'委托价',
    a41:'保证金',
    a42:'手续费',
    a43:'下单时间',
    a44:'操作',
    a45:'交易',
    a46:'类型',
    a47:'做多',
    a48:'做空',
    a49:'委托价',
    a50:'手数',
    a51:'手',
    a52:'倍数',
    a53:'市值',
    a54:'保证金',
    a55:'确认下单',
    a56:'做多',
    a57:'做空',
    a58:'市价',
    a59:'限价',
    a60:'买入数量',
    a61:'卖出数量',
    a62:'交易额',
    a63:'请您登录',
    a64:'请选择交易量',
    a65:'您的余额不足',
  },
  forexIndex:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'实时成交',
    a12:'价格',
    a13:'数量',
    a14:'累计',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'现货交易',
    a19:'买入',
    a20:'卖出',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'请输入数量',
    a25:'请输入交易额',
    a26:'可用',
    a27:'当前委托',
    a28:'持仓中',
    a29:'已完成',
    a30:'已撤单',
    a31:'货币',
    a32:'类型',
    a33:'委托价',
    a34:'委托数',
    a35:'手续费',
    a36:'下单时间',
    a37:'操作',
    a38:'交易',
    a39:'类型',
    a40:'买入',
    a41:'卖出',
    a42:'价格',
    a43:'限价',
    a44:'市价',
    a45:'委托价',
    a46:'委托量',
    a47:'确认下单',
    a48:'美股',
    a49:'买入',
    a50:'卖出',
    a51:'市价',
    a52:'限价',
    a53:'买入数量',
    a54:'卖出数量',
    a55:'交易额',
    a56:'请您登录',
    a57:'请选择交易量',
    a58:'您的余额不足!',
  },
  forgetpass:{
    a1:'忘记密码',
    a2:'邮箱',
    a3:'邮箱',
    a4:'手机号码',
    a5:'手机号码',
    a6:'邮箱',
    a7:'请输入邮箱',
    a8:'手机号',
    a9:'请选择',
    a10:'请输入手机号',
    a11:'新密码',
    a12:'请输入密码',
    a13:'确认新密码',
    a14:'请输入确认密码',
    a15:'确认',
    a16:'想起密码？',
    a17:'去登录',
    a18:'中国',
    a19:'美国',
    a20:'邮箱',
    a21:'手机号',
    a22:'请输入',
    a23:'请输入正确的邮箱地址',
    a24:'请输入正确的手机号',
    a25:'请输入密码',
    a26:'请输入确认密码',
    a27:'密码和确认密码不一致!',
    a28:'上传头像图片只能是 JPG 格式!',
    a29:'上传头像图片大小不能超过 2MB!',
  },
  futuresoption:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'期权交易',
    a19:'交易方式',
    a20:'选择到期时间',
    a21:'买入数量',
    a22:'最低买入',
    a23:'请输入买入数量',
    a24:'可用余额',
    a25:'买 涨',
    a26:'买 跌',
    a27:'当前委托',
    a28:'历史委托',
    a29:'名称',
    a30:'方向',
    a31:'委托数',
    a32:'时间',
    a33:'开仓价',
    a34:'平仓价',
    a35:'预计收益',
    a36:'收益',
    a37:'手续费',
    a38:'开始时间',
    a39:'结束时间',
    a40:'看涨',
    a41:'看跌',
    a42:'数量',
    a43:'收益',
    a44:'价格',
    a45:'确 定',
    a46:'请您登录',
    a47:'买入数量不能低于最低买入数量!',
    a48:'您的余额不足!'
  },
  futurespry:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'杠杆交易',
    a19:'做多',
    a20:'做空',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'选择倍数',
    a25:'下单张数',
    a26:'1张等于',
    a27:'请输入下单张数',
    a28:'市值',
    a29:'保证金',
    a30:'可用',
    a31:'当前委托',
    a32:'持仓中',
    a33:'已完成',
    a34:'已撤单',
    a35:'名称',
    a36:'类型',
    a37:'手数',
    a38:'倍数',
    a39:'委托价',
    a40:'保证金',
    a41:'手续费',
    a42:'下单时间',
    a43:'操作',
    a45:'交易',
    a46:'类型',
    a47:'做多',
    a48:'做空',
    a49:'委托价',
    a50:'手数',
    a51:'手',
    a52:'倍数',
    a53:'市值',
    a54:'保证金',
    a55:'确认下单',
    a56:'做多',
    a57:'做空',
    a58:'市价',
    a59:'限价',
    a60:'买入数量',
    a61:'卖出数量',
    a62:'交易额',
    a63:'请您登录',
    a64:'请选择交易量',
    a65:'您的余额不足!',
  },
  futuresIndex:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'实时成交',
    a12:'价格',
    a13:'数量',
    a14:'累计',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'现货交易',
    a19:'买入',
    a20:'卖出',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'请输入数量',
    a25:'请输入交易额',
    a26:'可用',
    a27:'当前委托',
    a28:'持仓中',
    a29:'已完成',
    a30:'已撤单',
    a31:'货币',
    a32:'类型',
    a33:'委托价',
    a34:'委托数',
    a35:'手续费',
    a36:'下单时间',
    a37:'操作',
    a38:'交易',
    a39:'类型',
    a40:'买入',
    a41:'卖出',
    a42:'价格',
    a43:'限价',
    a44:'市价',
    a45:'委托价',
    a46:'委托量',
    a47:'确认下单',
    a48:'买入',
    a49:'卖出',
    a50:'市价',
    a51:'限价',
    a52:'买入数量',
    a53:'卖出数量',
    a54:'交易额',
    a55:'请您登录',
    a56:'请选择交易量',
    a57:'您的余额不足!',
  },
  details:{
    a1:'文章详情',
  },
  helpcenterIndex:{
    a1:'帮助中心',
    a2:'上传头像图片只能是 JPG 格式!',
    a3:'上传头像图片大小不能超过 2MB!'
  },
  invitefriends:{
    a1:'邀请好友',
    a2:'邀请好友赚取更多奖励',
    a3:'邀请好友注册并验证实名后，即可联系客服领取现金奖励',
    a4:'我的邀请码',
    a5:'复制邀请链接',
    a6:'下载二维码',
    a7:'复制成功!',
    a8:'复制失败',
  },
  loanIndex:{
    a1:'贷款申请',
    a2:'申请记录',
    a3:'借款人',
    a4:'请输入借贷申请人',
    a5:'年龄',
    a6:'请输入年纪',
    a7:'联系信息',
    a8:'请输入联系信息',
    a9:'贷款金额',
    a10:'请输入申请贷款金额',
    a11:'护照编号',
    a12:'请输入本人护照编号',
    a13:'是否有配偶或子女',
    a14:'有',
    a15:'无',
    a16:'所在地详细地址',
    a17:'请输入所在地详细地址',
    a18:'从事的工作',
    a19:'请输入从事的工作',
    a20:'在职时长',
    a21:'请输入在职时长',
    a22:'工作详细地址',
    a23:'请输入工作详细地址',
    a24:'个人收入',
    a25:'请输入个人收入',
    a26:'家庭收入',
    a27:'请输入家庭收入',
    a28:'年收入',
    a29:'请输入年收入',
    a30:'其他收入来源',
    a31:'请输入其他收入来源',
    a32:'每年可存款的资金',
    a33:'请输入每年可存款的资金',
    a34:'现有债务的详细信息',
    a35:'请输入现有债务的详细信息',
    a36:'是否有401k或其他养老基金',
    a37:'有',
    a38:'无',
    a39:'是否有属于自己的房产',
    a40:'有',
    a41:'无',
    a42:'如有房产请提供房产归属证明照片',
    a43:'是否有属于自己的汽车',
    a44:'有',
    a45:'无',
    a46:'如有车辆请提供车辆归属证明照片',
    a47:'提供本人清晰的手持护照照片',
    a48:'立即申请',
    a49:'请输入贷款人！',
    a50:'请输入有效的年龄！',
    a51:'请输入正确的手机号！',
    a52:'请输入有效的贷款金额！',
    a53:'请输入护照编号！',
    a54:'请输入详细地址！',
    a55:'请输入从事工作！',
    a56:'请输入在职时长！',
    a57:'请输入工作详细地址！',
    a58:'请输入有效的个人收入！',
    a59:'请输入有效的家庭收入！',
    a60:'请输入有效的年收入！',
    a61:'请输入其他收入来源！',
    a62:'请输入有效的每年可存款金额！',
    a63:'请输入现有债务信息！',
    a64:'请上传房产证明照片！',
    a65:'请上传汽车照片！',
    a66:'请上传手持照片！',
    a67:'上传头像图片只能是 JPG 格式!',
    a68:'上传头像图片大小不能超过 2MB!'
  },
  loanrecord:{
    a1:'申请记录',
    a2:'申请人',
    a3:'申请金额',
    a4:'申请状态',
    a5:'申请时间',
    a6:'放款时间',
	a7:'待审核',
	a8:'待还款',
	a9:'未还款',
	a10:'已结束',
	a11:'已驳回',
  },
  lockupIndex:{
    a1:'锁仓挖矿',
    a2:'锁仓挖矿',
    a3:'智能投资账户',
    a4:'累计收益',
    a5:'项目周期',
    a6:'天',
    a7:'最低起投数',
    a8:'每日回报率',
    a9:'手续费',
    a10:'立即投资',
    a11:'我的订单',
    a12:'项目',
    a13:'开始时间',
    a14:'结束时间',
    a15:'累计收益',
    a16:'委托金额',
    a17:'手续费',
    a18:'状态',
    a19:'锁仓中',
    a20:'已完成',
    a21:'空数据',
    a22:'产品名称',
    a23:'项目周期',
    a24:'日返佣率',
    a25:'存款金额',
    a26:'请输入存款金额',
    a27:'可用金额',
    a28:'确 定',
    a29:'定单确认',
    a30:'15天锁仓',
    a31:'您的余额不足!',
    a32:'买入金额不能低于最小金额!',
  },
  lockdetails:{
    a1:'文章详情',
    a2:'质押介绍',
    a3:'什么是理财存币生息？',
    a4:'存币生息是壹款帮助有空闲数位资产和有借币需求的用户之间对接的产品。依托严格的风控体系，充分保障用户交易安全。',
    a5:'*请注意：历史收益不能代表未来收益。我们不承诺在壹定期限内以货币、实物、股权等管道还本付息或者给付任何其他形式的财产性收益回报。',
    a6:'产品优势：',
    a7:'稳健型',
    a8:'依托严格的风控体系，充分保障用户资产安全，安心享收益。',
    a9:'收益从何而来？',
    a10:'存币生息将存款用户的资金匹配给有借币需求的用户，从而使存款用户获得壹定的借币利息。',
    a11:'什么时候发放利息？',
    a12:'申购成功后用户可在T+1天收到T~T+1天时利息。',
    a13:'利息如何计算？',
    a14:'例如：以第一档门槛计算500USDT*0.15%=0.75USDT这是每天的收益。',
    a15:'赎回什么时候能到账？',
    a16:'周期内不能手动操作赎回，需要等待周期到自动赎回后均可实时到账。',
  },
  login:{
    a1:'邮箱',
    a2:'邮箱',
    a3:'手机号码',
    a4:'手机号码',
    a5:'邮箱',
    a6:'请输入账号',
    a7:'手机号',
    a8:'请选择',
    a9:'请输入手机号',
    a10:'密码',
    a11:'请输入密码',
    a12:'忘记密码',
    a13:'登 录',
    a14:'没有账号？',
    a15:'立即注册',
    a16:'中国',
    a17:'美国',
    a18:'邮箱',
    a19:'手机号',
    a20:'请输入',
    a21:'请输入密码',
    a22:'上传头像图片只能是 JPG 格式!',
    a23:'上传头像图片大小不能超过 2MB!',
  },
  loginpass:{
    a1:'登录密码',
    a2:'新密码',
    a3:'请输入新密码',
    a4:'确认新密码',
    a5:'请输入确认新密码',
    a6:'确认',
    a7:'请填写新密码!',
    a8:'请填写确认新密码!',
    a0:'新密码和确认密码不一致!',
  },
  myhold:{
    a1:'我的持有',
    a2:'加密货币',
    a3:'股票',
    a4:'外汇',
    a5:'期货',
    a6:'请输入币种名称搜索',
    a7:'重 置',
    a8:'可用',
    a9:'冻结',
    a10:'总余额',
    a11:'币种'
  },
  myproperty:{
    a1:'总资产',
    a2:'可用资产',
    a3:'股票折合',
    a4:'加密货币折合',
    a5:'立即充值',
    a6:'立即提现',
    a7:'我的持有',
    a8:'账户记录',
    a9:'全部',
    a10:'充值成功',
    a11:'提现',
    a12:'操作类型',
    a13:'金额',
    a14:'变动前',
    a15:'变动后',
    a16:'时间',
    a17:'产品名称',
    a18:'项目周期',
    a19:'日返佣率',
    a20:'存款金额',
    a21:'请输入存款金额',
    a22:'可用金额',
    a23:'确 定',
    a24:'定单确认',
    a25:'卖出',
  },
  myteam:{
    a1:'我的团队',
    a2:'佣金收益',
    a3:'一级好友',
    a4:'二级好友',
    a5:'三级好友',
    a6:'一级好友',
    a7:'二级好友',
    a8:'三级好友',
    a9:'账号',
    a10:'注册时间',
    a11:'返佣金额',
  },
  notice:{
    a1:'文章详情',
    a2:'平台公告',
    a3:'由于交易商(indexfund) 国际服务器和实时市场指数跟踪服务器的网络流量增加，我们致力于为客户提供短期交易服务，同时为了更好的保障您的权益，截止2024年7月25日平台已满十周年，为回馈新老客户门户，将举办庆典推出美元定期存款利率活动，具体详情可联系我司官网平台在线客服咨询！',
  },
  platform:{
    a1:'文章详情',
    a2:'平台介绍',
    a3:'NextEra Energy是一家在线股票和期权经纪公司，为零售和机构期权交易者和投资者提供交易平台。它是一家充满活力、以成长为导向的公司，拥有屡获殊荣的平台、服务和教育资源。',
    a4:'NextEra Energy的低利率与专业水平的技术相匹配，使 NextEra Energy在网上经纪行业独树一帜。在行业评论家 Barrons 对在线经纪商的年度评论中，它始终获得高排名。',
    a5:'NextEra Energy成立于创立于： 2013 年 4 月 18 日。',
    a6:'总部： 加利福尼亚门洛帕克',
    a7:'支持交易在纳斯达克和纽约证券交易所上市的股票和 ETF及加密货币',
  },
  quantitative:{
    a1:'量化交易',
    a2:'量化交易',
    a3:'智能投资账户',
    a4:'累计收益',
    a5:'项目周期',
    a6:'天',
    a7:'最低起投数',
    a8:'每日回报率',
    a9:'手续费',
    a10:'立即投资',
    a11:'我的订单',
    a12:'项目',
    a13:'开始时间',
    a14:'结束时间',
    a15:'累计收益',
    a16:'委托金额',
    a17:'手续费',
    a19:'状态',
    a20:'锁仓中',
    a21:'已完成',
    a22:'空数据',
    a23:'产品名称',
    a24:'项目周期',
    a25:'天',
    a26:'日返佣率',
    a27:'存款金额',
    a28:'请输入存款金额',
    a29:'可用金额',
    a30:'确 定',
    a31:'定单确认',
    a32:'15天锁仓',
    a33:'请登录!',
    a34:'您的余额不足!',
    a35:'买入金额不能低于最小金额!',
  },
  quantdetails:{
    a1:'文章详情',
    a2:'量化交易介绍',
    a3:'什么是理财量化交易？',
    a4:'量化交易是一款帮助拥有闲置数字资产的用户，通过专业算法实现自动化交易的理财产品。依托严格的风控体系，充分保障用户交易的安全性。',
    a5:'*请注意：历史收益不能代表未来收益。我们不承诺在一定期限内以货币、实物、股权等方式还本付息，或提供任何其他形式的固定收益回报。',
    a6:'产品优势：',
    a7:'稳健型',
    a8:'依托严格的风控体系和数据分析，保障用户资产安全，享受稳健收益。',
    a9:'收益从何而来？',
    a10:'量化交易通过市场数据分析和算法模型，在符合市场趋势的条件下进行自动化交易，从而为用户带来一定的收益。',
    a11:'什么时候发放收益？',
    a12:'申购成功后，用户可在 T+1 天起开始计息，并按周期收到收益。',
    a13:'收益如何计算？',
    a14:'例如：以第一档投资金额计算，500 USDT * 0.15% = 0.75 USDT，这是每天的预期收益。',
    a15:'赎回什么时候能到账？',
    a16:'投资周期内无法手动赎回，需等待周期结束后自动赎回，本金和收益将实时到账。'
  },
  quote:{
    a1:'加密货币',
    a2:'股票',
    a3:'外汇',
    a4:'期货',
    a5:'名称',
    a6:'最新成交价',
    a7:'24小时涨跌幅',
    a8:'24小时最高',
    a9:'24小时最低',
    a10:'24小时交易额',
    a11:'操作',
    a12:'交易',
  },
  recharge:{
    a1:'账户充值',
    a2:'充值提交后将于2-5分钟到账，请耐心等待，如未到账请联系客服',
    a3:'选择充值类型',
    a4:'转账地址',
    a5:'请输入转账地址',
    a6:'复制',
    a7:'充值数量',
    a8:'请输入充值数量',
    a9:'充值凭证',
    a10:'确认充值',
    a11:'请填写充值数量！',
    a12:'请填写转账地址！',
    a13:'请上传充值凭证！',
    a14:'复制成功！',
    a15:'复制失败',
    a16:'上传头像图片只能是 JPG 格式!',
    a17:'上传头像图片大小不能超过 2MB!',
  },
  rechargebank:{
    a1:'自选区',
    a2:'记录',
    a3:'我要买',
    a4:'我要卖',
    a5:'请选择',
    a6:'成交量',
    a7:'单价',
    a8:'数量',
    a9:'限额',
    a10:'购买',
    a11:'出售',
    a12:'单价',
    a13:'限额',
    a14:'联系客服',
    a15:'到账数量',
    a16:'购买',
    a17:'出售',
    a18:'保护资产安全，提高防范意识',
    a19:'交易信息',
    a20:'付款时限',
    a21:'30分钟',
    a22:'卖家昵称',
    a23:'Macigo',
    a24:'交易方式',
    a25:'Bank Card',
    a26:'交易前请阅读以下内容',
    a27:'银行卡转账切勿备注，否则不给予放币、直接封号，付款后需要提供付款后的交易截图。',
    a28:'购买',
    a29:'出售',
    a30:'请输入购买数量',
    a31:'请输入出售数量',
    a32:'复制成功',
    a33:'复制失败',
    a34:'上传头像图片只能是 JPG 格式!',
    a35:'上传头像图片大小不能超过 2MB!',
  },
  rechargerecord:{
    a1:'记录',
    a2:'购买记录',
    a3:'出售记录',
    a4:'申请状态',
    a5:'币种',
    a6:'数量',
    a7:'到账数量',
    a8:'申请失败',
    a9:'下单时间',
    a10:'申请失败',
    a11:'(预估)0.1279 USD',
    a12:'申请失败',
    a13:'(预估)0.8279 USD',
    a14:'购买',
    a15:'出售',
    a16:'请输入购买数量',
    a17:'请输入出售数量',
    a18:'复制成功！',
    a19:'复制失败',
    a20:'上传头像图片只能是 JPG 格式!',
    a21:'上传头像图片大小不能超过 2MB!',
  },
  register:{
    a1:'邮箱',
    a2:'邮箱',
    a3:'手机号码',
    a4:'手机号码',
    a5:'邮箱',
    a6:'请输入邮箱',
    a7:'手机号',
    a8:'请选择',
    a9:'请输入手机号',
    a10:'密码',
    a11:'请输入密码',
    a12:'确认密码',
    a13:'请输入密码',
    a14:'邀请码',
    a15:'请输入密码',
    a16:'注 册',
    a17:'已有账号？',
    a18:'去登录',
    a19:'中国',
    a20:'美国',
    a21:'邮箱',
    a22:'手机号',
    a23:'请输入',
    a24:'请输入正确的邮箱地址',
    a25:'请输入正确的手机号',
    a26:'请输入密码',
    a27:'请输入确认密码',
    a28:'密码和确认密码不一致!',
    a29:'上传头像图片只能是 JPG 格式!',
    a30:'上传头像图片大小不能超过 2MB!',
  },
  stockIndex:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'实时成交',
    a12:'价格',
    a13:'数量',
    a14:'累计',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'现货交易',
    a19:'买入',
    a20:'卖出',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'请输入数量',
    a25:'请输入交易额',
    a26:'可用',
    a27:'当前委托',
    a28:'持仓中',
    a29:'已完成',
    a30:'已撤单',
    a31:'货币',
    a32:'类型',
    a33:'委托价',
    a34:'委托数',
    a35:'手续费',
    a36:'下单时间',
    a37:'操作',
    a38:'交易',
    a39:'类型',
    a40:'买入',
    a41:'卖出',
    a42:'价格',
    a43:'限价',
    a44:'市价',
    a45:'委托价',
    a46:'委托量',
    a47:'确认下单',
    a48:'美股',
    a49:'买入',
    a50:'卖出',
    a51:'市价',
    a52:'限价',
    a53:'买入数量',
    a54:'卖出数量',
    a55:'交易额',
    a56:'请您登录',
    a57:'请选择交易量',
    a58:'您的余额不足!',
  },
  stockoption:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'期权交易',
    a19:'交易方式',
    a20:'选择到期时间',
    a21:'买入数量',
    a22:'最低买入',
    a23:'请输入买入数量',
    a24:'可用余额',
    a25:'买 涨',
    a26:'买 跌',
    a27:'当前委托',
    a28:'历史委托',
    a29:'名称',
    a30:'方向',
    a31:'委托数',
    a32:'时间',
    a34:'开仓价',
    a35:'平仓价',
    a36:'预计收益',
    a37:'收益',
    a38:'手续费',
    a39:'开始时间',
    a40:'结束时间',
    a41:'方向',
    a42:'看涨',
    a43:'看跌',
    a44:'数量',
    a45:'收益',
    a46:'价格',
    a47:'确 定',
    a48:'请您登录',
    a49:'买入数量不能低于最低买入数量',
    a50:'您的余额不足!',
  },
  stockpry:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格(USDT)',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格(USDT)',
    a16:'数量',
    a17:'时间',
    a18:'杠杆交易',
    a19:'做多',
    a20:'做空',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'选择倍数',
    a25:'下单张数',
    a26:'1张等于',
    a27:'请输入下单张数',
    a28:'市值',
    a29:'保证金',
    a30:'可用',
    a31:'当前委托',
    a32:'持仓中',
    a33:'已完成',
    a34:'已撤单',
    a35:'名称',
    a36:'类型',
    a37:'手数',
    a38:'倍数',
    a39:'委托价',
    a40:'保证金',
    a41:'手续费',
    a42:'下单时间',
    a43:'操作',
    a44:'交易',
    a45:'类型',
    a46:'做多',
    a47:'做空',
    a48:'委托价',
    a49:'手数',
    a50:'手',
    a51:'倍数',
    a52:'市值',
    a53:'保证金',
    a54:'确认下单',
    a55:'做多',
    a56:'做空',
    a57:'市价',
    a58:'限价',
    a59:'买入数量',
    a60:'卖出数量',
    a61:'交易额',
    a62:'请您登录',
    a63:'请选择交易量',
    a64:'您的余额不足!',
  },
  tradeIndex:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'实时成交',
    a12:'价格',
    a13:'数量',
    a14:'累计',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'现货交易',
    a19:'买入',
    a20:'卖出',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'请输入数量',
    a25:'请输入交易额',
    a26:'可用',
    a27:'当前委托',
    a28:'持仓中',
    a29:'已完成',
    a30:'已撤单',
    a31:'货币',
    a32:'类型',
    a33:'委托价',
    a34:'委托数',
    a35:'手续费',
    a36:'下单时间',
    a37:'操作',
    a38:'撤单',
    a39:'类型',
    a40:'买入',
    a41:'卖出',
    a42:'价格',
    a43:'限价',
    a44:'市价',
    a45:'委托价',
    a46:'委托量',
    a47:'确认下单',
    a48:'买入',
    a49:'卖出',
    a50:'市价',
    a51:'限价',
    a52:'买入数量',
    a53:'卖出数量',
    a54:'交易额',
    a55:'请您登录',
    a56:'请选择交易量',
    a57:'您的余额不足!',
  },
  tradeleverage:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'杠杆交易',
    a19:'做多',
    a20:'做空',
    a21:'市价',
    a22:'限价',
    a23:'请输入交易额',
    a24:'选择倍数',
    a25:'下单张数',
    a26:'1张等于',
    a27:'请输入下单张数',
    a29:'市值',
    a30:'保证金',
    a31:'可用',
    a32:'当前委托',
    a33:'持仓中',
    a34:'已完成',
    a35:'已撤单',
    a36:'名称',
    a37:'类型',
    a38:'涨',
    a39:'跌',
    a40:'倍数',
    a41:'委托价',
    a42:'保证金',
    a43:'手续费',
    a44:'下单时间',
    a45:'操作',
    a46:'撤单',
    a47:'市价全平',
    a48:'止盈止亏',
    a49:'类型',
    a50:'做多',
    a51:'做空',
    a52:'委托价',
    a53:'手数',
    a54:'手',
    a55:'倍数',
    a56:'市值',
    a57:'保证金',
    a58:'确认下单',
    a59:'委托价',
    a60:'倍数',
    a61:'委托数量',
    a62:'止盈金额',
    a63:'请输入止盈金额',
    a64:'止亏金额',
    a65:'请输入止亏金额',
    a66:'确认修改',
    a67:'做多',
    a68:'做空',
    a69:'市价',
    a70:'限价',
    a71:'买入数量',
    a72:'卖出数量',
    a73:'交易额',
    a74:'请您登录',
    a75:'请选择交易量',
    a76:'您的余额不足',
	a77:'结算盈亏'
  },
  tradeoption:{
    a1:'请输入币种搜索',
    a2:'币种',
    a3:'最新价',
    a4:'涨幅',
    a5:'涨幅',
    a6:'24H高',
    a7:'24H低',
    a8:'24H交易量',
    a9:'交易中',
    a10:'盘口',
    a11:'价格',
    a12:'数量',
    a13:'累计',
    a14:'实时成交',
    a15:'价格',
    a16:'数量',
    a17:'时间',
    a18:'期权交易',
    a19:'交易方式',
    a20:'选择到期时间',
    a21:'买入数量',
    a22:'最低买入',
    a23:'请输入买入数量',
    a24:'可用余额',
    a25:'买 涨',
    a26:'买 跌',
    a27:'当前委托',
    a28:'历史委托',
    a29:'名称',
    a30:'方向',
    a31:'委托数',
    a32:'时间',
    a33:'手续费',
    a34:'开始时间',
    a35:'结束时间',
    a36:'方向',
    a37:'看涨',
    a38:'看跌',
    a39:'数量',
    a40:'收益',
    a41:'价格',
    a42:'确 定',
    a43:'请您登录',
    a44:'买入数量不能低于最低买入数量!',
    a45:'您的余额不足!',
	a46:'涨',
	a47:'跌',
	a48:'平',
	a49:'开仓价',
	a50:'平仓价',
	a51:'预计收益',
  },
  transactionpass:{
    a1:'交易密码',
    a2:'新密码',
    a3:'请输入新密码',
    a4:'确认新密码',
    a5:'请输入确认新密码',
    a6:'确认',
    a7:'请填写新密码！',
    a8:'请填写确认新密码！',
    a9:'新密码和确认密码不一致！',
    a10:'上传头像图片只能是 JPG 格式!',
    a11:'上传头像图片大小不能超过 2MB!'
  },
  usercenter:{
    a1:'邀请码',
    a2:'安全等级',
    a3:'高',
    a4:'可用余额',
    a5:'总资产',
    a6:'实名认证',
    a7:'银行卡绑定',
    a8:'登录密码',
    a9:'交易密码',
    a10:'邀请好友',
    a11:'平台介绍',
    a12:'平台公告',
    a13:'帮助中心',
    a14:'免息贷款',
    a15:'请登录！',
	a16:'财务记录',
	a17:'充值记录',
	a18:'提现记录'
  },
  wallet:{
    a1:'我的钱包',
    a2:'未绑定',
    a3:'已绑定',
    a4:'立即绑定',
    a5:'钱包类型',
    a6:'钱包地址',
    a7:'请输入钱包地址',
    a8:'确认钱包地址',
    a9:'请输入确认钱包地址',
    a10:'确认',
    a11:'绑定钱包'
  },
  withdraw:{
    a1:'账户提现',
    a2:'为了保障资金安全，当您的账户安全性原则更改时或则密码修改时，我们会对您的账户进行人工审核，请耐心等待工作人员邮件信息',
    a3:'选择提现钱包',
    a4:'提现数量',
    a5:'请输入提现数量',
    a6:'手续费',
    a7:'到账余额',
    a8:'提现卡号',
    a9:'请输入提现卡号',
    a10:'确认提现',
    a11:'请填写提现数量!',
    a12:'请填写提现卡号!',
  },
  footer:{
    a1:'在金融和技术的交叉点创建解决方案',
    a2:'*请注意，交易所的假数字正在运行。TrustExchange 不提供资产交易所，也不与交易所有任何隶属关系。 TrustExchange 建议全部 TrustExchange 资产用户在交易前验证交易所的有效性。',
    a3:'版权所有 @ TrustExchange',
    a4:'在线客服',
    a5:'请扫码',
    a6:'问题解答',
    a7:'返回顶部',
  },
  header:{
    a1:'行情',
    a2:'加密货币',
    a3:'现货交易',
    a4:'杠杆交易',
    a5:'期权交易',
    a6:'股票',
    a7:'现货交易',
    a8:'杠杆交易',
    a9:'期权交易',
    a10:'外汇',
    a11:'现货交易',
    a12:'杠杆交易',
    a13:'期权交易',
    a14:'期货',
    a15:'现货交易',
    a16:'杠杆交易',
    a17:'期权交易',
    a18:'锁仓',
    a19:'锁仓挖矿',
    a20:'量化交易',
    a21:'我的资产',
    a22:'个人中心',
    a23:'登录',
    a24:'注册',
    a25:'个人中心',
    a26:'我的资产',
    a27:'退出账号',
    a28:'中国',
    a29:'法国',
    a30:'德国',
    a31:'美国',
    a32:'朝鲜',
    a33:'韩国',
  },
  bindcardRecord:{
	  a1:'绑定记录',
	  a2:'姓名',
	  a3:'卡号',
	  a4:'银行',
	  a5:'联系电话',
	  a6:'支行',
	  a7:'支行代码'
  }
}
