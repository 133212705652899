import request from '@/utils/request'

// 登录
export function Login (data) {
  return request({
    url: '/pcLoginApi/login',
    method: 'post',
    data:data
  })
}

// 登录--获取国家
export function getCountryCode () {
  return request({
    url: '/system/coin/getDictDataByType/country_code',
    method: 'get'
  })
}

// 登出
export function LogOut () {
  return request({
    url: '/pcLoginApi/loginout',
    method: 'get'
  })
}

