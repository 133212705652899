// src/locales/en.js
export default {
  home: {
    title: 'Welcome',
    description: 'This is the home page'
  },
  about: {
    title: 'About Us',
    description: 'This is the about page'
  }
}
