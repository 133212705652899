import request from '@/utils/request'

// 帮助中心
export function GetHelpData () {
  return request({
    url: '/pcApi/helpCenter',
    method: 'get'
  })
}

//获取详情页
export function GetHelpDetails (id) {
  return request({
    url: '/pcApi/helpCenterInfo/'+id,
    method: 'get'
  })
}
