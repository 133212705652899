import request from '@/utils/request'

// 查询首页table数据列表(多个参数查询条件)
export function listData (query) {
  return request({
    url: '/pcApi/indexList',
    method: 'get',
    params: query
  })
}
// 获取首页股票类型
export function GetStockType (data) {
  return request({
    url: '/system/coin/getDictDataByType/stock_type',
    method: 'get'
  })
}

// 获取首页BTC的价格和涨幅
export function getBTC (data) {
  return request({
    url: '/pcApi/btcInfo',
    method: 'get'
  })
}
//平台配置
export function webSetting () {
  return request({
    url: '/pcApi/webSetting',
    method: 'get'
  })
}
