<template>
  <div>
    <div  class="bottom"><!--底部-->
        <div class="bottomOneBox">
          <div class="bottomOneLogo">
            <div class="OneLogo">
                <img :src="logoUrl" class="el-image__inner" style="width: 160px;height: 160px">
            </div>
            <div class="OneContent">
              {{$t('footer.a1')}}
            </div>
            <div class="OneIcon">
                <div class="OneIconImg"><img :src="bottomOne" class="el-image__inner" style="width: 30px; height: 30px;"></div>
                <div class="OneIconImg"><img :src="bottomTwo" class="el-image__inner" style="width: 30px; height: 30px;"></div>
                <div class="OneIconImg"><img :src="bottomthree" class="el-image__inner" style="width: 30px; height: 30px;"></div>
            </div>
          </div>
          <div class="bottomOneHelp">
           <div class="OneHelpList" >
              <div class="OneHelpText" v-for="(item, serveindex) in tableData" :key="serveindex" @click="jump(item.id)">{{item.name}}</div>
           </div>
          </div>
        </div>

        <div class="bottomTwoBox" style="text-align: left;">
          {{$t('footer.a2')}}
        </div>
        <div class="bottomThreeBox">
          {{$t('footer.a3')}}
        </div>
    </div>

    <div class="bottomImmobilization"><!--底部右侧的固定栏:客服、问题解答，扫码下载等-->
      <!-- 第一个按钮，悬停时显示在线客服 -->
      <el-button
        class="mybutton"
        type="success"
		@click="toChat"
        @mouseenter.native="showHoverText($t('footer.a4'), $event)"
        @mouseleave.native="hideHoverText"
      >
        <span class="Immobil">
          <svg focusable="false" class="ImmobilImg" data-icon="customer-service" width="2em" height="2em" fill="#000000" aria-hidden="true" viewBox="64 64 896 896">
            <path d="M512 128c-212.1 0-384 171.9-384 384v360c0 13.3 10.7 24 24 24h184c35.3 0 64-28.7 64-64V624c0-35.3-28.7-64-64-64H200v-48c0-172.3 139.7-312 312-312s312 139.7 312 312v48H688c-35.3 0-64 28.7-64 64v208c0 35.3 28.7 64 64 64h184c13.3 0 24-10.7 24-24V512c0-212.1-171.9-384-384-384zM328 632v192H200V632h128zm496 192H696V632h128v192z"></path>
          </svg>
        </span>
        <div class="hover-content" v-if="hoverText === $t('footer.a4')">{{$t('footer.a4')}}</div>
      </el-button>

      <!-- 第二个按钮，悬停时显示图片和"请扫码" -->
      <el-button
        class="mybutton"
        type="success"
        @mouseenter.native="showHoverText($t('footer.a5'), $event)"
        @mouseleave.native="hideHoverText"
      >
        <span class="Immobil">
          <svg focusable="false" class="ImmobilImg" data-icon="download" width="1em" height="1em" fill="#000000" aria-hidden="true" viewBox="64 64 896 896">
            <path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
          </svg>
        </span>
        <div class="hover-content" v-if="hoverText === $t('footer.a5')">
          <img :src="qrCode" alt="QR Code" class="el-image__inner" style="width: 50px; height: 50px; margin-bottom: 5px;"/>
          <div>{{$t('footer.a5')}}</div>
        </div>
      </el-button>

      <!-- 第三个按钮，悬停时显示问题解答 -->
      <el-button
        class="mybutton"
        type="success"
        @mouseenter.native="showHoverText($t('footer.a6'), $event)"
        @mouseleave.native="hideHoverText"
      >
        <span class="Immobil">
          <svg focusable="false" class="ImmobilImg" data-icon="question-circle" width="1em" height="1em" fill="#000000" aria-hidden="true" viewBox="64 64 896 896">
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
            <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
          </svg>
        </span>
        <div class="hover-content" v-if="hoverText === $t('footer.a6')">{{$t('footer.a6')}}</div>
      </el-button>

      <!--第四个按钮,返回顶部-->
      <el-button
        class="mybutton"
        type="success"
        @mouseenter.native="showHoverText($t('footer.a7'), $event)"
        @mouseleave.native="hideHoverText"
        v-show="showBackToTop"
        @click="scrollToTop"
      >
        <span class="Immobil">
          <svg focusable="false" class="ImmobilImg" data-icon="arrow-up" width="1em" height="1em" fill="none" stroke="#000000" stroke-width="48" aria-hidden="true" viewBox="0 0 1024 1024">
             <path d="M512 832c-4.4 0-8-3.6-8-8V256l-180 180c-3.1 3.1-8.2 3.1-11.3 0-3.1-3.1-3.1-8.2 0-11.3l200-200c3.1-3.1 8.2-3.1 11.3 0l200 200c3.1 3.1 3.1 8.2 0 11.3-3.1 3.1-8.2 3.1-11.3 0L520 256v568c0 4.4-3.6 8-8 8z"></path>
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
          </svg>
        </span>
        <div class="hover-content" v-if="hoverText === $t('footer.a7')">{{$t('footer.a7')}}</div>
      </el-button>
    </div>
  </div>
</template>

<script>
import { GetHelpData } from '@/api/helpcenter/index'
import {
		webSetting
	} from '@/api/index/index'
export default {
  name: 'Footer',
  props: {},
  data () {
    return {
      hoverText: '',
      qrCode: null, // 二维码图片
      bottomOne: '/static/dibu1.png',
      bottomTwo: '/static/dibu2.png',
      bottomthree: '/static/dibu3.png',
      bottomImg: '/static/diBan.png' ,// 底部的图
	  logoUrl: null,
	  domain: `${process.env.VUE_APP_BASE_API}`,
	  chatUrl:null,
      tableData: [],
      showBackToTop: false,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
  },
  created () {
    this.getlist();
	this.getWebSetting();
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 200) { // Show button when scrolled 200px
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },
	getWebSetting() {
		webSetting().then(res => {
			if (res.data.webLogo == null) {
				this.logoUrl = '/static/logo.png';
			} else {
				this.logoUrl = this.domain + res.data.webLogo;
			}
			if (res.data.appAddress == null) {
				this.qrCode = '/static/qr.png';
			} else {
				this.qrCode = this.domain + res.data.appAddress;
			}
			this.chatUrl=res.data.chatAddress;
		})
	},
	toChat(){
		window.location.href=this.chatUrl
	},
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    // 底部右侧图片鼠标悬停时显示文本并计算位置
    showHoverText (text, event) {
      this.hoverText = text
    },
    // 底部右侧图片鼠标移出时隐藏文本
    hideHoverText () {
      this.hoverText = ''
    },
    //获取帮助中心
    getlist(){
      GetHelpData().then(res=>
        this.tableData = res.data
      )
    },
    jump(id){
      this.$router.push({path: '/helpdata',query: { id: id}}); // 跳转到 '帮助详情' 页面
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottom{ // 底部
    z-index: 99;
    position: relative;
    box-shadow: 1px 3px 13px #0000000f;
    padding-top: 32px;
    height: auto;
    background: #151515;color:#fff;
    font-size: 14px;
    .bottomOneBox{
      width: 1200px;
      margin: 0 auto 32px;
      display: flex;
      align-items: center;

      .bottomOneLogo{
        width: 280px;
        height: 100%;
        flex-direction: column;
        font-size: 24px;

        .OneLogo{
           width: 160px;
           height: 160px
        }

        .OneContent{
          margin-top: 20px;
          font-size: 14px;
          line-height: 1.4;
          text-align: left
        }

        .OneIcon{
          margin-top: 20px;
          display: flex;
          gap: 20px;
          .OneIconImg{
            width: 30px;
            height: 30px;
          }
        }
      }

      .bottomOneHelp{
        flex: 1;
        .OneHelpList{
           padding: 0 64px;
           display: grid;
           grid-template-columns: repeat(2, 1fr);
           grid-gap: 48px;
           grid-row-gap: 24px;
           .OneHelpText{
               overflow: hidden;
               text-overflow: ellipsis;
               line-break: anywhere;
               display: -webkit-box;
               -webkit-box-orient: vertical;
               -webkit-line-clamp: 1;
               transition: .2s;
               cursor: pointer;
           }

           .OneHelpText:hover{
              color:#03C800;
           }
        }
      }
    }

    .bottomTwoBox{
      width: 1200px;
      margin: 0 auto;
      line-height: 1.3;
      padding: 10px 0;
      font-size: 14px;
    }

    .bottomThreeBox{
       width: 1200px;
       margin: 0 auto;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
       height: 50px;
       border-top: 1px solid #424242;
    }
   }

  // 底部的右侧按钮(客服、下载，问题解答，返回顶部按钮等固定在右侧底部)
  .bottomImmobilization {
       z-index:199;
       box-sizing: border-box;
       position: fixed;
       bottom: 20px;
       right: 20px;
       display: flex;
       flex-direction: column;
       align-items: center;
       .mybutton {
         position: relative;
         border-radius: 50%;
         height: 55px;
         width: 55px;
         margin-bottom: 10px;
         &:first-child {
           margin-left: 10px;
         }
         .Immobil{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
           .ImmobilImg{
               display: inline-block;
               line-height: 1;
           }
         }
         .hover-content{
             position: absolute;
             left: -120px;
             background-color:#424242;
             top:12%;
             color: white;
             padding: 5px;
             border-radius: 4px;
             display: flex;
             flex-direction: column;
             align-items: center;
             width: 100px;
             text-align: center;
         }

         .hover-content img {
           margin-bottom: 5px;
         }
       }
  }
</style>
